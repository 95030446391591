import React, { useState } from "react";
import { auth, storage, db } from "../firebase";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";
import "../style/Register.scss";

const Register = () => {
  const [err, setErr] = useState(false);
  const navigate = useNavigate();

  const [previewImg, setPreviewImg] = useState("");

  const imgSelectHandler = (i) => {
    i.preventDefault();
    setPreviewImg(URL.createObjectURL(i.target.files[0]));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const displayName = e.target[0].value;
    const email = e.target[1].value;
    const password = e.target[2].value;
    const file = e.target[3].files[0];

    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);

      const storageRef = ref(storage, displayName);

      const uploadTask = uploadBytesResumable(storageRef, file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            await updateProfile(res.user, {
              displayName,
              photoURL: downloadURL,
            });
            await setDoc(doc(db, "users", res.user.uid), {
              uid: res.user.uid,
              displayName,
              email,
              photoURL: downloadURL,
            });

            await setDoc(doc(db, "messages", res.user.uid), {});
            navigate("/");
          });
        }
      );
    } catch (err) {
      setErr(true);
    }
  };

  return (
    <div className="form-container register">
      <div className="form-wrapper">
        <span className="title">Sign up</span>
        <form onSubmit={handleSubmit}>
          <input type="text" placeholder="Name" autoComplete="name" required />
          <input
            type="email"
            placeholder="Email"
            autoComplete="email"
            required
          />
          <input
            type="password"
            placeholder="Password"
            autoComplete="new-password"
            required
          />
          <div className="avatar-wrapper">
            <img src={previewImg} alt="" />
            <input
              type="file"
              accept="image/*"
              id="avatar"
              onChange={imgSelectHandler}
              required
            />
          </div>
          <label id="avatar-label" htmlFor="avatar">Be sheep, and smile.</label>
          <button type="submit">Register</button>
          {err && <span>Are you a wolf ?</span>}
        </form>
        <p>
          You are already part of the herd ? <Link to="/login">Login</Link>
        </p>
      </div>
    </div>
  );
};

export default Register;
