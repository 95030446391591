import React from "react";

const ToolBar = ({ sidebar, openSidebar }) => {
  return (
    <div className="tool-bar">
      <div
        className={sidebar ? "tool-bar-btn--open" : "tool-bar-btn"}
        onClick={openSidebar}
      >
        <i className="fa-solid fa-arrow-left"></i>
      </div>
    </div>
  );
};

export default ToolBar;
