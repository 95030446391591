import React, { useState, useContext } from "react";
import Chatbox from "../components/Chatbox";
import Sidebar from "../components/Sidebar";
import ToolBar from "../components/ToolBar";
import { ThemeContext } from "../context/ThemeContext";
import "../style/Dashboard.scss";

const Dashboard = () => {
  const [sidebar, setSidebar] = useState(true);

  const toggleSidebar = () => {
    setSidebar((prevState) => !prevState);
  };

  // theme
  const { theme } = useContext(ThemeContext);

  return (
    <div className="dashboard" data-theme={theme ? "light" : "dark"}>
      <div className="container">
        <ToolBar sidebar={sidebar} openSidebar={toggleSidebar} />
        <Sidebar sidebar={sidebar} closeSidebar={toggleSidebar} />
        <Chatbox />
      </div>
    </div>
  );
};

export default Dashboard;
