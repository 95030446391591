import React, { useContext } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { AuthContext } from "../context/AuthContext";
import "../style/Dashboard.scss";
import { useState } from "react";
import { useRef } from "react";
import ToggleTheme from "./ToggleTheme";

const NavBar = () => {
  const { currentUser } = useContext(AuthContext);

  // consts for open / close menu button
  const [open, setOpen] = useState(false);
  const menuBtnRef = useRef();
  const contentRef = useRef();

  window.addEventListener("click", (el) => {
    if (el.target !== contentRef.current && el.target !== menuBtnRef.current) {
      setOpen(false);
    }
  });

  return (
    <div className="navbar">
      <div className="dropdown">
        <button
          ref={menuBtnRef}
          onClick={() => setOpen(!open)}
          className="dropdown-btn"
        ></button>
        {open && (
          <div ref={contentRef} className="dropdown-content">
            <ul>
              <li
                ref={contentRef}
                onClick={() => signOut(auth)}
                className="logout"
              >
                <i className="fa-solid fa-door-open"></i>
                Logout
              </li>
              <li ref={contentRef} className="theme-switch">
                <ToggleTheme />
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className="user">
        <span className="name">{currentUser.displayName}</span>
        <img src={currentUser.photoURL} alt="" />
      </div>
    </div>
  );
};

export default NavBar;
