import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
import ReactTimeago from "react-timeago";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import enStrings from "react-timeago/lib/language-strings/en";
import "../style/Dashboard.scss";

const DisplayMessage = ({ message }) => {
  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);

  const [show, setShow] = useState(false);

  const timestamp = message.date;
  const formatter = buildFormatter(enStrings);

  const ref = useRef();

  useEffect(() => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  return (
    <div
      ref={ref}
      className={`display-msg ${
        message.senderId === currentUser.uid && "owner"
      }`}
    >
      <div className="display-msg-info">
        <img
          src={
            message.senderId === currentUser.uid
              ? currentUser.photoURL
              : data.user.photoURL
          }
          alt=""
        />
      </div>

      <div className="display-msg-content">
        {message.text && <p onClick={() => setShow(!show)}>{message.text}</p>}
        {message.img && <img src={message.img} alt="" />}
        {show && (
          <span className="timer">
            <ReactTimeago
              date={new Date(timestamp?.toDate()).toUTCString()}
              minPeriod={60}
              formatter={formatter}
            />
          </span>
        )}
      </div>
    </div>
  );
};

export default DisplayMessage;
