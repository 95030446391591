import React, { useState } from "react";
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import "../style/Login.scss";

const Login = () => {
  const [err, setErr] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target[0].value;
    const password = e.target[1].value;

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/");
    } catch (err) {
      setErr(true);
    }
  };

  return (
    <div className="form-container login">
      <div className="avatar-upload"></div>
      <div className="form-wrapper">
        <span className="title">Sign in</span>
        <img
          src="https://cdn.pixabay.com/photo/2016/11/13/21/46/sheep-1822137_1280.jpg"
          alt=""
        />
        <form onSubmit={handleSubmit}>
          <input type="email" placeholder="Email" autoComplete="email" />
          <input
            type="password"
            placeholder="Password"
            autoComplete="password"
          />
          <button type="submit">Enter</button>
          {err && <span id="error-login">Reserved for the members of the herd.</span>}
        </form>
        <p>
          You don't have a herd ? <Link to="/register">Register</Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
