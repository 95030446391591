import React, { useContext } from "react";
import Messages from "./Messages";
import Input from "./Input";
import { ChatContext } from "../context/ChatContext";
import "../style/Dashboard.scss";

const Chatbox = () => {
  const { data } = useContext(ChatContext);

  return (
    <div className="chatbox">
      <div className="chat-info">
        <span className="contact-name">
          <img src={data.user?.photoURL} alt="" />
          <span className="display-name">{data.user?.displayName}</span>
        </span>
      </div>
      <Messages />
      <Input />
    </div>
  );
};

export default Chatbox;
