import React from "react";
import Navbar from "./NavBar";
import Searchbar from "./Searchbar";
import Contacts from "./Contacts";
import "../style/Dashboard.scss";

const Sidebar = ({sidebar, closeSidebar}) => {

  return (
    <div className={sidebar?"sidebar sidebar--open":"sidebar"}>
      <Navbar />
      <Searchbar />
      <Contacts onClick={closeSidebar}/>
    </div>
  );
};

export default Sidebar;
