import React, { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import "../style/Dashboard.scss";
import "../style.scss";

export default function ToggleTheme() {
  const { toggleTheme, theme } = useContext(ThemeContext);

  return (
    <div onClick={toggleTheme} className="btn-theme">
      {theme ? (
        <i className="fa-solid fa-moon"></i>
      ) : (
        <i className="fa-solid fa-sun"></i>
      )}
    </div>
  );
}
